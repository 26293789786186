
import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import Chart from '@/components/common/chart.cmp.vue'
import { useActivityRepository } from '@/api/repository/activity-repository'
import { useAuthGetters } from '@/store'
import { DataTableHeader } from 'vuetify'
import { useStringColor } from '@/utils/string-color'
import { mdiChevronLeft, mdiChevronRight, mdiCalendar } from '@mdi/js'

export default defineComponent({
  name: 'reporting-summary-overview',
  components: {
    Chart,
  },
  setup(_, { root }) {
    const repository = useActivityRepository()
    const { currentUser } = useAuthGetters()
    const locale = currentUser.value.locale

    const dateOptions: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }

    const today = new Date()
    const datePickerValues = ref([
      dateToString(addDaysToDate(today, 1 - today.getDay())),
      dateToString(addDaysToDate(today, 1 - today.getDay() + 4)),
    ])
    const selectedDates = computed(() => {
      const mappedDates = datePickerValues.value.map((dateString) => new Date(dateString))

      // if only one date is currently selected
      if (mappedDates.length === 1) {
        mappedDates[1] = addDaysToDate(mappedDates[0], 5)
      }
      return mappedDates.sort((a, b) => a.getTime() - b.getTime())
    })
    const dateMenuRef = ref(null)
    const showDateMenu = ref(false)

    const filters = computed(() => {
      return {
        dateFrom: dateToString(selectedDates.value[0]),
        dateTo: dateToString(selectedDates.value[1]),
      }
    })

    function addDaysToDate(date: Date, days: number) {
      const tmp = new Date(date.getTime())
      tmp.setUTCDate(tmp.getUTCDate() + days)
      return tmp
    }

    function dateToString(date: Date) {
      return date.toISOString().split('T')[0]
    }

    const { getAll, data, isLoading } = repository.useGetAll()

    const selected = ref(root.$t('reporting.preSelect.today').toString())

    const preSelect = [
      // doughnutChartConfig
    ]

    const clickLeft = () => {
      datePickerValues.value.splice(0, 1, dateToString(addDaysToDate(selectedDates.value[0], -7)))
      datePickerValues.value.splice(1, 1, dateToString(addDaysToDate(selectedDates.value[1], -7)))
    }

    const clickRight = () => {
      datePickerValues.value.splice(0, 1, dateToString(addDaysToDate(selectedDates.value[0], 7)))
      datePickerValues.value.splice(1, 1, dateToString(addDaysToDate(selectedDates.value[1], 6)))
    }

    const tableHeaders: DataTableHeader[] = [
      {
        text: root.$tc('project.title', 2).toString(),
        value: 'projectName',
      },
      {
        text: root.$t('duration').toString(),
        value: 'hours',
      },
      // {
      //   text: root.$t('percentage').toString(),
      //   value: 'percentage',
      // },
    ]

    const tableItems = computed<{ projectName: string; hours: number }[]>(() => {
      const projects = data.value.reduce<any>((projects, activity) => {
        const project = projects[activity.projectId] || { projectName: activity.projectName, hours: 0 }
        project.hours += activity.hours
        projects[activity.projectId] = project
        return projects
      }, {})
      return Object.values(projects)
    })

    const { getColor } = useStringColor()

    const doughnutChartConfig = computed(() => {
      const labels = tableItems.value.map((item) => item.projectName)
      const data = tableItems.value.map((item) => item.hours)
      const backgroundColor = labels.map((l) => getColor(l))
      return {
        type: 'doughnut',
        data: {
          labels,
          datasets: [
            {
              data,
              backgroundColor,
            },
          ],
        },
        maintainAspectRatio: false,
        options: {
          plugins: {
            legend: {
              position: 'bottom',
            },
          },
        },
      }
    })

    watch(
      filters,
      (values) => {
        getAll({ params: { ...values, userId: currentUser.value.id, size: 20000 } })
      },
      {
        immediate: true,
      }
    )

    const barChartConfig = computed(() => {
      const dateDiff = selectedDates.value[1].getTime() - selectedDates.value[0].getTime()
      const daySpan = Math.ceil(dateDiff / (1000 * 60 * 60 * 24)) + 1
      const labels = [...Array(daySpan).keys()]
        .map((num) => addDaysToDate(selectedDates.value[0], num))
        .map((date) => date.toLocaleDateString(locale, dateOptions))

      interface DateEntry {
        date: string
        hours: number
      }

      interface DatasetEntry {
        name: string
        dates: { [key: string]: DateEntry }
      }

      const entries = data.value.reduce<{ [key: string]: DatasetEntry }>((entries, activity) => {
        const entry = entries[activity.projectId] || { name: activity.projectName, dates: {} }
        const date = new Date(activity.date).toLocaleDateString(locale, dateOptions)
        let day = entry.dates[date] || { date: date, hours: 0 }
        day.hours += activity.hours
        entry.dates[date] = day
        entries[activity.projectId] = entry
        return entries
      }, {})

      const datasets = Object.values(entries).map((entry) => {
        return {
          label: entry.name,
          data: Object.values(entry.dates),
          backgroundColor: getColor(entry.name),
        }
      })

      return {
        type: 'bar',
        data: {
          labels,
          datasets,
        },
        options: {
          parsing: {
            xAxisKey: 'date',
            yAxisKey: 'hours',
          },
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
          maintainAspectRatio: false,
        },
      }
    })

    return {
      locale,
      dateOptions,
      selected,
      preSelect,
      data,
      isLoading,
      tableHeaders,
      tableItems,
      barChartConfig,
      doughnutChartConfig,
      clickLeft,
      clickRight,
      icons: {
        mdiChevronLeft,
        mdiChevronRight,
        mdiCalendar,
      },
      datePickerValues,
      selectedDates,
      showDateMenu,
      dateMenuRef,
    }
  },
})
